.root {
  width: 360px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 24px 0px;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  transform: translate3d(0px, 0px, 0px);
  touch-action: auto;
  margin-right: 20px;
  margin-bottom: 10px;
  display: none;
  pointer-events: all;
}
.open {
  display: flex;
  animation: customTransition 250ms ease-in-out both;
}
.close {
  display: none;
}

@keyframes customTransition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .root {
    width: 100%;
    margin-right: 0;
  }
}
