/* Globals and overwrites here */

@font-face {
  font-family: "Bariol";
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  src: local('Bariol Regular'), url('/fonts/bariol_regular-webfont.woff') format('woff');
}

@font-face {
  font-family: "Bariol";
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  src: local('Bariol Bold'), url('/fonts/bariol_bold-webfont.woff') format('woff');
}

.MuiToolbar-regular {
  height: 56px;
}

.carousel .slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5 !important;
}

.carousel .control-dots {
  padding: 0;
  width: 20% !important;
  justify-self: anchor-center;

  background-color:grey !important;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: rgba(0, 0, 0, 0.979) !important;
  margin: 0 4px !important;
}

.carousel .control-dots .dot:only-child {
  display: none !important;
}

@media (max-width: 767px) {
  .carousel.carousel-slider .control-arrow {
    /* display: none !important; */
  }
}

.carousel.carousel-slider .control-arrow {
  background: none !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 10px solid rgb(0, 0, 0) !important;
 }

.carousel .control-next.control-arrow:before {
  border-left: 10px solid rgb(0, 0, 0) !important;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(85deg, #48AC98 0%, #45C4AA 100%);
}

*::-webkit-scrollbar-thumb:hover {
  /* background: rgba(52, 8, 100, .7); */
  background: #48ac98;
}