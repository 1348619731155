.get10X {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem 2rem;

    div {
        margin: 1rem 0;
        width: 100%;
        height: 1px;
        background: #dedede;
    }
}

.root {
    position: relative;
    align-items: center;
    align-self: flex-end;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
    color: rgb(79, 206, 93);
    cursor: pointer;
    display: flex;
    font-size: 2em;
    height: 48px;
    justify-content: center;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
    outline: transparent;
    user-select: none;
    width: 48px;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    pointer-events: all;
  }
  
  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    .root {
      margin-right: 0;
      height: 48px;
      width: 48px;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 100px;
    right: 32px;
    z-index: 999;
    pointer-events: none;
  }
  
  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
  @media (min-width: 320px) and (max-width: 480px) {
    .main {
      left: 50%;
      right: 0;
      bottom: 120px;
      transform: translateX(-50%);
      width: 90%;
    }
  }

  .item {
    @media (min-width: 600px) {
      width: 194px;
    }
    @media (max-width: 599px) {
      width: 50%;
    }
  }
  .load {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 30px !important;
    cursor: pointer;
    color: #48ac98;
    &:hover {
      text-decoration: underline;
    }
  }