.orderReviewContainer {
  padding-top: 90px;
  height: 100vh;
  text-align: left;
}

.topBarToolbar {
  position: relative;
  justify-content: flex-end;
}

.topBarTitle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.statusSection {
  text-align: center;
}

.card {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
}

.cardContent {
  padding-bottom: 16px !important;
  text-align: left;
  margin-top: .5rem;
}

.productList {
  margin-top: 10px;
  margin-bottom: 20px;
}

.productImage {
  width: 80px;
  border-radius: 0.25rem;
  overflow: hidden;
  line-height: 0;

  img {
    width: 100%;
    height: auto;
  }
}

.options {
  text-transform: capitalize;
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 10px;
  }

  span,
  strong{
    margin-left: 5px;
    margin-right: 5px;
    min-width: 30px;
  }

  button {
    background: none;
    border: none;
  }
}

.checkoutBtn {
  width: 100%;
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}