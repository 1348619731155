@import '../../mixins.scss';

.item{
  min-width: 110px;
  max-width: 111px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:first-of-type {
    margin-left: 5px;
  }
  outline: none;
}

.loader{
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 2px 5px rgba(52,58,67,0.08),0px 5px 15px rgba(52,58,67,0.08);
  @include skeleton-box();
}

.active {
  svg {
    animation-name: loading;
    opacity: 1;
  }
}

.category {
  position: relative;
  width: 84px;
  height: 84px;

}

.image {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: #f5f5f5;
  object-fit: cover;
  box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 2px 5px rgba(52,58,67,0.08),0px 5px 15px rgba(52,58,67,0.08);
}

.border {
  margin: 0 auto;
  position: relative;
  top: -1px;
  left: -2px;
  width: 82px;
  height: 82px;
  fill: none;
  stroke: #48ac98;
  stroke-width: 2px;
  stroke-dasharray: 1;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation-name: none;
  animation-duration: 750ms;
  animation-direction: alternate;
  animation-iteration-count: 2;
  animation-timing-function: ease-in-out;
  transition: opacity 150ms linear;
  opacity: 0;
}

@keyframes loading {
  100% {
    stroke: #266678;
    stroke-dasharray: 5;
    transform: rotate(90deg);
  }
}

.caption {
  display: block;
  font-weight: 600 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  line-height: normal !important;
  width: 100%;
  padding-top: 0.25rem;
  color: rgba(10, 31, 68, 0.75);
  &.active {
    color: #3b73ff;
  }
}
