.imageWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.lazyImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}