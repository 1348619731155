.notFoundContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fafafa;
}

.notFoundWrapper {
  height: 100%;
}

.notFound {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  text-align: center;
  color: #607D8B;
}

.notFoundIcon {
  margin-bottom: 8px;
  font-size: 8rem!important;
}
