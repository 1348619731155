.sendOrderContainer {
  text-align: left;
}

.topHeader {
  display: block;
  padding: 20px;
}

.checkbox {
  margin-right: 4px !important;
}

.numberFieldWrapper {
  margin-top: 8px;
  margin-bottom: 8px;
}

.numberField {
  margin-top: 8px;
  display: flex;
  align-items: center;
  padding: 8px 14px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(52,58,67,0.10), 0px 1px 2px rgba(52,58,67,0.08), 0px 1px 4px rgba(52,58,67,0.08);
}

.numberFieldFocus {
  box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 2px 5px rgba(52,58,67,0.08),0px 5px 15px rgba(52,58,67,0.08);
}

.numberFieldIcon {
  width: 10%;
  color: #48ac98;
  line-height: 0;
}

.numberFieldCode {
  width: 15%;
}

.numberFieldWhatsapp {
  width: 85%;
  text-align: center;
  padding-left: 10px !important;
  padding-right: 10px !important;
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
   -webkit-appearance: none; 
    margin: 0; 
  }
}

.dateField {
  input[type=date], 
  input[type=time] {
    /* min-height: 1.1876em!important; */
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
  }
}

.sendOrderBtn {
  margin-top: 8px !important;
  color: #ffffff !important;
  background: #21c59f;
  background-image: linear-gradient(-20deg, #00cdac 0%, #8ddad5 100%);
  box-shadow: 5px 5px 9px -2px rgba(0, 205, 172,0.2),
              -5px -5px 9px 0px rgba(0, 205, 172,0.14)!important;
  &:hover {
    background: #21c59f !important;
  }
  &:disabled {
    background-image: none !important;
    background: #E0E0E0 !important;
    box-shadow: none !important;
  }
}

.sendOrderBtnOnline {
  margin-top: 8px !important;
  // margin-bottom: 10px !important;
  color: #ffffff !important;
  // background: #45ACCE;
  background: linear-gradient(45deg, #03a9f4, #4fc3f7);
  box-shadow: 0px 2px 4px rgba(84, 182, 209, 0.5) !important;
  transition: ease 1s background;
  &:hover {
    background: #03a9f4 !important;
  }
  &:disabled {
    background-image: none !important;
    background: #E0E0E0 !important;
    box-shadow: none !important;
  }
}

.sendOrderShopBtn {
  margin-top: 1.5rem !important;
  color: #ffffff !important;
  background: #3367d6;
  background-image: linear-gradient(-20deg, #3367d6 0%, #5c85de 100%);
  box-shadow: 5px 5px 9px -2px rgba(51, 103, 214,0.2),
              -5px -5px 9px 0px rgba(51, 103, 214,0.14);
  &:hover {
    background: #3367d6;
  }
  &:disabled {
    background-image: none;
    background: #E0E0E0;
  }
}

.sendOrderBtnProgress {
  color: #21c59f;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.formSelect {
  min-height: 51px;
  font-size: 15px;
  margin-top:5px;
  outline:2px rgba(52,58,67,0.12);
  color:#9c9aaa;
  box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 2px 5px rgba(52,58,67,0.08),0px 5px 15px rgba(52,58,67,0.08);
  border: 1px solid #d8d7d7;
  border-radius: 8px;
  padding:0 10px;
}

.validate {
  outline: #f66 solid 1px;
}
