@import '../../mixins.scss';

.item {
  width: 50%;
  text-decoration: none !important;
  color: inherit;

  @media(min-width: 600px) {
    width: calc(100% / 3);
  }

  img {
    object-fit: cover;
    width: 100%;
    min-height: 150px;
  }
}

.card {
  margin: 0 0.5rem 1rem;
  overflow: hidden;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 2px 5px rgba(52,58,67,0.08),0px 5px 15px rgba(52,58,67,0.08);
  padding-bottom: 0.5rem;
  &.loading {
    @include skeleton-box();
  }
}

.image {
  position: relative;
  margin: 0;
  line-height: 0;
  border-bottom: solid #f3f1f2 1px;
}

.caption {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 20px);
  text-align: left;
}

.caption {
  margin: 0.5rem 0.5rem 0 !important;
  color: rgba(15, 41, 48, 0.75) !important;
  line-height: 1.5 !important;
}

.priceContainer {
  text-align: left;
  padding: 0 0.5rem 0 !important;
}

.price {
  display: inline-block;
  font-size: 1rem !important;

  &.withDiscount {
    margin-left: 0.25rem !important;
    font-size: 0.875rem !important;
    text-decoration: line-through;
    color: #8A94A6;
  }
}

.discountPrice {
  display: inline-block;
  font-size: 1rem !important;
}

.discountChip {
  position: absolute;
  top: 8px;
  left: 8px;
  height: 16px !important;
  color: #ffffff !important;
  font-size: 0.625rem !important;
  letter-spacing: 0.25px;
  line-height: 1;
  background-color: #CDA35F !important;

  span {
    overflow: visible;
    padding-left: 6px;
    padding-right: 6px;
  }
}