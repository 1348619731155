@import '../../mixins.scss';

.header {
  position: relative;
}

.content {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;
}

.coverWrapper {
  position: relative;
  top: 0;
  left: 0;
  height: 135px;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-color: #CAD7CA;

  &.loading {
    @include skeleton-box();
  }
}

.headerCartBtnLink {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.headerCartBtn {
  background-color: #ffffff !important;
  box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 5px 10px rgba(52,58,67,0.08),0px 15px 35px rgba(52,58,67,0.08);
  &:hover {
    background-color: #ffffff;
  }
}

.headerSearchBtn {
  position: absolute !important;
  top: 1rem;
  right: 4rem;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 5px 10px rgba(52,58,67,0.08),0px 15px 35px rgba(52,58,67,0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width .275s ease !important;
  width: 36px;
  
 &::after {
    content: "";
    display: none;
    white-space: nowrap;
  }

  &:hover {
    width: 100px;
    border-radius: 20px;
    box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 5px 10px rgba(52,58,67,0.08),0px 15px 35px rgba(52,58,67,0.08);
    
    &::after {
      display: block;
      content: "Buscar";
      margin: 0 7px;
      font-size: 16px;
    }
  }
}

.headerSearchBtnCart {
  right: 1rem;
}

.shopBranding {
  margin-bottom: 1rem;
  display: flex;
}

.avatar {
  margin-top: -20px;
  width: 90px;
  min-width: 90px;
  height: 90px;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 5px 10px rgba(52,58,67,0.08),0px 15px 35px rgba(52,58,67,0.08);
  background-color: #ffffff;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
    line-height: 0;
  }

  &.loading {
    @include skeleton-box();
  }
}

.titleContainer {
  margin-left: 0.75rem;
}

.shopTitle {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.75rem !important;

  &.loading {
    @include skeleton-box();
  }
}

.verifiedBadge {
  position: absolute;
  display: inline;
  margin-left: .25rem;
  line-height: 0;
}

.verifiedBadgeIcon {
  vertical-align: middle;
}

.whatsappBadge {
  display: inline-block;
  margin-top: 2px;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  line-height: 0;
  background: #F1F2F4;
}

.whatsappBadgeIcon {
  vertical-align: middle;
  font-size: 1rem !important;
  color: #48AC98;
}

.whatsappBadgeCaption {
  vertical-align: middle;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #53627C;
}