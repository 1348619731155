.optionsWrapper {
  padding-top: 16px;
}

.list {
  padding-top: 18px !important;
  padding-bottom: 8px !important;
}

.listItem {
  box-shadow: 0 -1px 0 0 #ECEFF1;
}
