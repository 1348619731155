.wrapper {
  overflow: hidden;
  position: relative;
}

.list {
  padding-top: 1rem;
  display: flex;
  overflow: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  gap: 15px;
  &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
      box-shadow: none;
      opacity: 0;
  }
}

.arrow {
  position: absolute !important;
  top: calc(50% - 10px);
  transform: translateY(-50%);
  opacity: 1;
  margin: 10px !important;
  width: 22px;
  height: 22px;
  color: #53627C !important;
  background-color: #ffffff !important;
  transition: opacity 0.15s ease !important;

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
  }

  svg {
    font-size: 0.75rem;
  }
}

.arrowLeft {
  left: 8px;
}

.arrowRight {
  right: 8px;
}

.arrowHide {
  opacity: 0;
  pointer-events: none;
}