.wrapper {
  //margin-bottom: 4rem;
  overflow: hidden;
}

.list {
  margin: 1rem 0.5rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  animation-name: none;
  animation-duration: 250ms;
  animation-fill-mode: both;
}

.fade {
  animation-name: fadeIn;
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
