.bgDialog {
    position: absolute;
    width: 100%;
    height: 200vh;
    background-color: rgba(80, 80, 80, 0.534);
    z-index: 1;
    top: 0;
    animation: scale-up-ver-top .8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    
    @keyframes scale-up-ver-top {
        0% {
          transform: scaleY(0);
          transform-origin: 100% 0%;
        }
        100% {
          transform: scaleY(1);
          transform-origin: 100% 0%;
        }
      }
}
.bgDialogOut {
    position: absolute;
    width: 100%;
    height: 200vh;
    background-color: rgba(80, 80, 80, 0.534);
    z-index: 1;
    top: 0;
    animation: scale-down-ver-top .6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    @keyframes scale-down-ver-top {
        0% {
            transform: scaleY(1);
            transform-origin: 100% 0%;
            }
            100% {
            transform: scaleY(0);
            transform-origin: 100% 0%;
            }
        }
}

.sectionDialog {
    width: 90%;
    height: 350px;
    background-color: #f1f1f1;
    border: 1px solid #ffffff;
    border-radius: 15px;
    top: 20px;
    position: absolute;
    z-index: 2;
    left: 5%;
    overflow: hidden !important;
    animation: scale-up-center 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    @keyframes scale-up-center {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
    }
}
.sectionResults {
    overflow-y: scroll;
    height: 80%;
}
.sectionResults::-webkit-scrollbar {
    width: 5px;
}
.sectionResults::-webkit-scrollbar-thumb {
    border-radius: 10px;
}
.sectionDialogOut {
    width: 90%;
    max-height: 350px;
    background-color: #f1f1f1;
    border: 1px solid #ffffff;
    border-radius: 15px;
    top: 20px;
    position: absolute;
    z-index: 2;
    left: 5%;
    overflow: scroll;
    animation: scale-down-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    @keyframes scale-down-center {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
}

.inputContainer {
    display: flex;
    margin: 20px auto;
    width: 95%;
    justify-content: space-between;
    align-items: center;
}
.input {
    box-shadow: 1px 2px 5px 0px #838383ba;;
    font-size: 16px;
    color: #838383;
    width: 92%;
    border-radius: 4px;
    border: 1px solid #dfdfdf;
    padding: 5px;
    @media screen and (max-width: 899px) {
        width: 90%;
       }
    &:focus {
        outline: 1px solid#48ac98;
        box-shadow: 1px 2px 5px 0px #48ac98;;
    }
}
.imagePreview {
    width: 150px;
    opacity: 0.4;
    border-radius: 4px;
    margin: 50px;
}
.cardContainer {
    display: flex;
    flex-direction: column;
}
.card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
    width: 100%;
    margin: 0 auto;
    padding: 12px 0 12px 5%;
    align-items: center;
    text-decoration: none;
}
.cardImage {
    width: 60px;
    height: 60px;
    border-radius: 4px;
}
.text {
    font-size: 1rem;
    color: #5a5a5a;
    text-transform: uppercase;
}
.closeBtn {
    background-color: #cfcece4d;
    color: #838383;
    border-radius: 20px;
    cursor: pointer;
}
