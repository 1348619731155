.cartContainer {
  min-height: 100vh;
  text-align: left;
}

.topHeader {
  display: block;
  padding: 20px;
}

.topHeaderTitle {
  margin-top: -20px;
}

.productList {
  margin-top: 8px;
  margin-bottom: 16px;
}

.card {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 8px;
  background: #f5f5f5;
  box-shadow: 8px 8px 16px #ddd,
              -8px -8px 16px #fff;
}

.cardContent {
  padding: 0 !important;
}

.cardProductContent {
  padding: 8px !important;
}

.cardProductInner {
  display: flex;
}

.productImage {
  margin: 0;
  display: block;
  max-height: 96px;
  max-width: 96px;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  border-radius: 8px;
  background: #f5f5f5;

  img {
    width: 100%;
    height: 100%;
  }
}

.productDetailsWrapper {
  padding-left: 8px;
  width: 100%;
}

.productDetails {
  padding-right: 30px;
}

.removeProduct {
  position: absolute !important;
  top: 12px;
  right: 12px;
  color: #e33371;
  background: #f5f5f5;
  box-shadow: 2px 2px 4px #d0d0d0,
              -2px -2px 4px #ffffff;
}

.removeRowContainer {
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

 .CancelRemoveRowBtn {
  background-color: #ffffff;
  color: #48ac98;
  border:none;
  cursor: pointer;
  
 }

 .RemoveRowBtn {
  background-color: #E04F4F;
  color: #ffffff;
  margin: 0 0 0 12px;
  border:none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 4px;
  @media (max-width: 600px) {
    margin: 0;
  }
  }

.continueShoppingBtn {
  margin-bottom: 20px !important;
  width: 100% !important;
  color: #3367d6 !important;
  border-radius: 8px !important;
  background: linear-gradient(145deg, #ffffff, #ececec) !important;
  box-shadow:  5px 5px 9px #d0d0d0,
               -5px -5px 9px #ffffff !important;
}

.continueShoppingBtnIcon {
  font-size: 1rem !important;
}

.cartShopBtnLink {
  text-decoration: none;
}

.addMoreProductsBtn {
  margin-bottom: 16px !important;
  font-size: 0.875rem !important;
}

.cartShopBtn {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  width: 100% !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  background: #21c59f !important;
  background-image: linear-gradient(-20deg, #00cdac 0%, #8ddad5 100%) !important;
  box-shadow: 5px 5px 9px -2px rgba(0, 205, 172,0.2),
              -5px -5px 9px 0px rgba(0, 205, 172,0.14) !important;
  &:hover {
    background: #21c59f !important;
  }
  &:disabled {
    background-image: none !important;
    background: #E0E0E0 !important;
    box-shadow: none !important;
  }
}

.cartShopOnlineBtn {
  margin-top: 20px;
  margin-bottom: 0;
  width: 100%;
  color: #ffffff;
  border-radius: 8px;
  background: #3367d6;
  background-image: linear-gradient(-20deg, #3367d6 0%, #5c85de 100%);
  box-shadow: 5px 5px 9px -2px rgba(51, 103, 214,0.2),
              -5px -5px 9px 0px rgba(51, 103, 214,0.14);
  &:hover {
    background: #3367d6;
  }
  &:disabled {
    background-image: none;
    background: #E0E0E0;
  }
}

.cartEmpty {
  margin-top: 64px;
  margin-bottom: 64px;
  text-align: center;
  color: #607D8B;
}

.cartEmptyIcon {
  margin-bottom: 8px;
  font-size: 4.75rem;
}

.optionsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: column;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  justify-content: flex-end;
    padding: 0px 36px;
  padding: 0px 15px;
  &:last-child{
    margin-bottom: 0;
  }
}

.optionHidden {
  display: none;
}

.optionTitle {
  margin: 12px 0 0 !important;
}
.quantityInStock {
  gap: 35px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -4px;
  left: 80px;
  @media (max-width: 600px) {
    left: 25px;
    top:-2px;
    gap: 20px;
  }
  p{
    margin: 0;
  }
}
.optionButtonGroup {
  display: flex;
  margin: 3px 0 8px;
  @media (max-width: 600px) {
    margin: 0;
    display: flex;
  }
}

.priceSpan {
  margin: 0 47px 0 0;
  @media (max-width: 600px) {
    margin: 0 7px 0 0;
    font-weight: 600;
  }
}

.optionButtonIcon {
  width: 24px;
  height: 24px;
  padding: 0;
  background: #f5f5f5;
  box-shadow: 2px 2px 4px #d0d0d0,
              -2px -2px 4px #ffffff;
}

.optionButtonIconSvg {
  font-size: 0.75rem !important;
}

.optionQuantity {
  width: 30px;
  line-height: 24px;
  text-align: center;
}

.optionInputInner {
  padding: 0;
  text-align: center;
}

.addVariationsButton {
  padding: 1px 6px 2px !important;
  margin: 16px 16px 16px 0 !important;
  border-radius: 8px !important;
}

.addVariationsButtonIcon{
  margin-right: 2px;
  font-size: 0.75rem !important;
}

.moreOptionsContainer {
  width: 100%;
  background: #F5F5FA;
  border-radius: 8px;
  padding: 16px;
}

.addVariationsButtonCaption {
  font-size: 0.875rem !important;
}

.optionsList{
  padding-left: 0;
  list-style: none;
  text-transform: capitalize;

  li {
    display: flex;
    justify-content: space-between;
  }
}

.optionsContainer {
  display: flex;
  flex-direction: column;
  padding-top: 0;
}

.productContainer{
  display: flex;
  flex-direction: column;
}

.CurrentOptions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.moreOptionsButtons{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.optionsSelect {
  border-color: #c2c8d0;
  height: 1.5rem;
  border-radius: 5px;
  text-transform: capitalize;
}

.defaultOption {
  padding: 0 16px;
  width: 100%;
  display: flex;
  align-items: center;
}

.variant {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 16px;
}

.variantOption {
  margin-right: 1rem;
}

.nameAndVariant {
  position: relative;
  top: 18px;
}


.minimumAmountError {
  background: #FCF3EA;
  border-radius: 8px;
  padding: 16px 21px;
  font-size: 16px;
  line-height: 21px;
  margin-top: 22px;

  strong {
    color: #48ac98;
  }
}

.subTotalProduct {
  margin: 1rem
}

.inputAmount {
  border: 1px solid #a7a6a6;
  border-radius: 3px;
  display: flex;
  margin: 0px 5px;
  text-align: center;
  width: 37px;

  &:focus {
    outline: #21c59f solid 1px;
  }

  &:hover {
    outline: #00ff6a solid 1px;
  }

  ::placeholder {
    color: #000;
  }
}

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

[data-tooltip]:before {
  position: absolute;
  bottom: 130%;
  left: 22%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}
//tringle
[data-tooltip]:after {
  position: absolute;
  bottom: 130%;
  left: 47%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
