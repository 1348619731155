@import '../../mixins.scss';

.optionsContainer {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.addToCartButton {
  position: fixed !important;
  padding: 8px 16px !important;
  bottom: 10px;
  
  @media screen and (min-width: 900px) {
    left: 26.2%;
   width: calc(48% - 6px) !important;
  }
  @media screen and (max-width: 899px) {
    left: 9px;
    width: 95% !important;
   }
  
  &:disabled{
   pointer-events: all !important;
  }
}

.optionList {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 5px;

  button {
    text-transform: capitalize;
  }
}


.carouselSection {
  transition: 1s;
  border-bottom: solid #f3f1f2 1px;
  aspect-ratio: 1/1;
  width: 100%;
  background: #f5f5f5;
  position: relative;

  :global {
    .carousel-root {
      height: 100%;
    }

    .carousel.carousel-slider {
      height: 100%;
      
      .slider-wrapper {
        height: 100%;
      }
      
      .control-arrow {
        z-index: 999;
        color: white;
        opacity: 1; // Make arrows always visible

        &.control-next {
          &:before {
            border-right: 0 !important;
            border-left: 8px solid grey !important;
          }
        }

        &.control-prev {
          &:before {
            border-left: 0 !important;
            border-right: 8px solid grey !important;
          }
        }
      }

      .slide {
        background: transparent;
      }
    }

    .thumbs-wrapper {
      margin: 10px 0 !important;
      
      .thumbs {
        display: flex;
        justify-content: center;
        gap: 8px;
        padding: 0;
      }

      .thumb {
        border: 2px solid transparent;
        opacity: 0.6;
        transition: all 0.3s ease;

        &.selected,
        &:hover {
          border: 2px solid #1976d2;
          opacity: 1;
        }
      }
    }
  }

  &.loading {
    @include skeleton-box();
    min-height: 100vw;
    
    img {
      opacity: 0 !important;
    }
  }

  &.noImages {
    height: 0;
    min-height: 0;
    margin-bottom: 3rem;

    * {
      height: 0;
    }
  }

  img {
    animation: fadein 0.5s;
    opacity: 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}


@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.productNameContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.productShareBtn {
  margin-left: 8px;
  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.price {
  display: inline-block;

  &.withDiscount {
    margin-left: 0.25rem !important;
    font-size: 1.25rem !important;
    text-decoration: line-through;
    color: #8A94A6;
  }
}

.discountPrice {
  display: inline-block;
  color: #48AC98;
}

.filesSection {
  margin: 20px 0;
}

.filesContainer {
  display: flex;
  align-items: center;
}

