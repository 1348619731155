.postWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%; // Creates a perfect square aspect ratio
  background-color: #f5f5f5; // Light background for empty spaces
}

.imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productImage {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain; // Maintains aspect ratio while fitting within container
    object-position: center;
  }
  > div { // Target LazyImage wrapper
    width: 100%;
    height: 100%;
  }
}

.viewItemsBtn {
  position: absolute !important;
  bottom: 16px;
  right: 16px;
  font-weight: 400 !important;
  line-height: 1 !important;
  border-radius: 30px !important;
  color: #53627C;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 2px rgba(52,58,67,0.10),0px 2px 5px rgba(52,58,67,0.08),0px 5px 15px rgba(52,58,67,0.08);
}