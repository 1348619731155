.navbarToolbar {
  display: flex;
  justify-content: space-between;
}

.navbarButton {
  color: #ffffff !important;
  background-color: rgba(0, 0, 0, 0.35) !important;
  box-shadow: none;
  &:hover {
    background: rgba(0,0,0,0.5) !important;
  }
}
